import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-addemployee',
  templateUrl: './addemployee.component.html',
  styleUrls: ['./addemployee.component.css']
})
export class AddemployeeComponent implements OnInit {
  @ViewChild('employeeForm') public any: NgForm;
  edit: boolean; emp: any; id: any; res: any; img: any;
  constructor(private _emp: TellerService, private _use: NavComponent, private _nav: Router, private _route: ActivatedRoute) { }

  ngOnInit() {
    this.formReset();
    this.getAll();
  }

  getAll() {
    this.id = this._route.snapshot.paramMap.get('id');
    this.getEmp(this.id); // console.log(this.id);
  }

  getEmp(id: any) {
    if (id && id !== 0) {
      this.edit = true;
      this._emp.getEmployee(id).subscribe((data: any) => {
        this.emp = data;
      }, (err: HttpErrorResponse) => { alert(err.statusText); });
    } else {
      this.edit = false;
      this.formReset();
    }
  }

  fileUpload(event) {
    // console.log(event);
    this.img = event.target.files[0];
    this._emp.Upload(this.img, 'User').subscribe((data: any) => {
      this.emp.image = data; console.log(data);
    });
  }

  postEmployee() {
    this._emp.postEmployee(this.emp).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this._use.showMsg('success', 'Employee Added', 'Added to list Successfully');
      this._nav.navigate(['/employee']);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Employee');
    });
  }

  putEmployee() {
    this.emp.muserid = this._use.active.Id; this.emp.mdate = this._use.date;
    this._emp.putEmployee(this.emp.employeeid, this.emp).subscribe((data: any) => {
      this.res = data; this.formReset(this.any);
      this._use.showMsg('success', 'Employee Updated', 'Details Updated Successfully');
      this._nav.navigate(['/employee']);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.emp = { fullname: '', email: '', image: '', salary: 0,
        mobile: '', position: '', dateofbirth: null, nationality: '', address: '',
        userid: this._use.active.Id, date: this._use.date, muserid: null, mdate: null };
    }
  }

}
