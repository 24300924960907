import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { SetupService } from 'src/app/services/setup.service';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';

@Component({
  selector: 'app-finance-fund-manager',
  templateUrl: './finance-fund-manager.component.html',
  styleUrls: ['./finance-fund-manager.component.css']
})
export class FinanceFundManagerComponent implements OnInit {
  @ViewChild('fundForm') public any: NgForm;
  employees: any[]; to: any[]; fund: any[]; edit: boolean; tf: any; res: any; response: any; search: string = '';
  types: any[] = ["Shortage","Excess"];
  constructor(private api: ApiService, private _setup: SetupService, private _api: TellerService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    // this.getAllFund();
    // this.getAllEmployee();
  }

  getAllEmployee() {
    this.api.get('employee?page1&limit=100').subscribe((data: any) => {
      this.employees = data.data; //this.from = data.data;
    }, (err: HttpErrorResponse) => { });
    
  }

  getAllFund() {
    this.api.get('fund').subscribe((data: any) => {
      this.fund = data.data; this.response = data;
    }, (err: HttpErrorResponse) => { 
      // alert(err.error);
    });
  }

  editFund(val) {
    this.tf = val;
    this.edit = true;
  }
  
  getMore(page,limit) {
    this.api.get('fund?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.fund = data.data; this.response = data; // console.log(data);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  saveFund() {
    if(this.tf.fundid > 0) {
      this.putFund();
    } else {
      this.postFund();
    }
  }

  postFund() {
    this.api.post('fund',this.tf).subscribe((data: any) => {
      this.formReset();
      this._use.showMsg('success', 'Payment Successfull', 'Payment has been posted successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Payment Fail', 'Unable to Post Payment Details');
    });
  }
  
  putFund() {
    this.api.put('fund/' + this.tf.fundid,this.tf).subscribe((data: any) => {
      this.formReset();
      this._use.showMsg('success', 'Payment Successfull', 'Payment has been posted successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Payment Fail', 'Unable to Post Payment Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.tf = { 
        code: null, type: null, amount_cleared: 0, amount: 0, employeeid: null,
        status: null, userid: this._use.active.Id, date: this._use.date
      };
      this.edit = false;
    }
  }

}
