import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-norminal',
  templateUrl: './setup-norminal.component.html',
  styleUrls: ['./setup-norminal.component.css']
})
export class SetupNorminalComponent implements OnInit {
  @ViewChild('nominalForm') public any: NgForm;
  edit: boolean; list: boolean; nom: any; res: any; response: any; search: string = '';
  main: any[] = []; nominal: any[] = [];
  constructor(private api: ApiService, private _setup: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllNominal();
  }

  getAllNominal() {
    this._setup.getNominals().subscribe((data: any) => {
      this.nominal = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Nominal List');
    });
    this._setup.getMainNominals().subscribe((data: any) => {
      this.main = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch MainNominal List');
    });
  }

  getMore(page,limit) {
    this.api.get('nominal?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.nominal = data.data; this.response = data; // console.log(data);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  editNom(data) {
    // this.nom = this.sequence.find(s => s.sequenceId === id).
    this.nom = data;
    this.edit = true; this.list = true;
  }
  delNom(dat) {
    this._setup.delNominal(dat.nominalid).subscribe((data: any) => {
      this.res = data; this.nominal.slice(dat);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete Nominal'); });
  }

  postNominal() {
    this._setup.postNominal(this.nom).subscribe((data: any) => {
      this.res = data; this.nominal.push(data);
      this.formReset(); // this.getAllNominal();
      this._use.showMsg('success', 'Nominal Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Nominal');
    });
  }

  putNominal() {
    this.nom.muserid = this._use.active.id; this.nom.mdate = new Date();
    this._setup.putNominal(this.nom.nominalid, this.nom).subscribe((data: any) => {
      this.res = data; this.formReset(); this.edit = false;
      this._use.showMsg('success', 'Nominal Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.nom = {
        nominalid: 0, gltype: '', code: '', description: '', balancetype: '',
        status: 'Active', allowjournal: true, mainnominalid: '', merchantid: this._use.active.merchantid,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null };
    }
  }
  listView() {
    this.list = true; this.edit = false;
  }
  back() {
    this.list = false;
  }

}
