import { Component, OnInit, ViewChild } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ManagerService } from 'src/app/services/manager.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-session',
  templateUrl: './setup-session.component.html',
  styleUrls: ['./setup-session.component.css']
})
export class SetupSessionComponent implements OnInit {
  @ViewChild('sessionForm') public any: NgForm;
  ses: any; add: Boolean; edit: Boolean; curr: Date; response: any; search: string = '';
  session: any[] = []; branch: any[] = [];
  constructor(private api: ApiService, private _setup: ManagerService, private _use: NavComponent) { }

  ngOnInit() {
    this.curr = new Date();
    this.formReset();
    this.getAllSession();
  }

  getAllSession() {
    this._setup.getSessions().subscribe((data: any) => {
      this.session = data.data; this.response = data;
      this._use.showMsg('success', 'Session List', 'Fetched Session list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Session List Fail', 'Unable to Fetch Session List');
    });
  }
  
  getMore(page,limit) {
    this.api.get('session?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.session = data.data; this.response = data; // console.log(data);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  close(id) {
    this._setup.closeSession(id, this._use.active.id).subscribe((data: any) => {
      this.getAllSession();
      this._use.showMsg('success', 'Session Close', 'Session Has Been Closed Successfully');
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
  }

  postSession() {
    this._setup.postSession(this.ses).subscribe((data: any) => {
      this.ses = data; this.session.push(data);
      this.formReset(); this.getAllSession();
      this._use.showMsg('success', 'Session Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Session');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.ses = { sessiondate: null, status: 'Active', merchantid: this._use.active.merchantid,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }

  back() {
    this.formReset();
  }

}
