import { Component, OnInit, ViewChild } from '@angular/core';
import { TellerService } from 'src/app/services/teller.service';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SetupService } from 'src/app/services/setup.service';

@Component({
  selector: 'app-teller-voucher',
  templateUrl: './teller-voucher.component.html',
  styleUrls: ['./teller-voucher.component.css']
})
export class TellerVoucherComponent implements OnInit {
  @ViewChild('voucherForm') public any: NgForm;
  nom: any[]; tellers: any[]; grant: boolean; tf: any; res: any;
  constructor(private _setup: SetupService, private _api: TellerService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllNominals();
  }

  getAllNominals() {
    this._setup.getNominalsByGlType('Expense').subscribe((data: any) => {
      this.nom = data;
    }, (err: HttpErrorResponse) => { alert(err.statusText); });
    this._api.getTellers().subscribe((data: any) => {
      this.tellers = data; this.from(this._use.active.Id);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Nominal Fail', 'Unable to Get Nominal Code Pls Check your Connection');
    });
  }

  findId(data) {
    this.tf.tellerid = data.find(i => i.id === this._use.active.Id);
  }
  from(val) {
    console.log(val);
    this.tellers.forEach((e: any) => {
      if (e.id === val) {
        this.tf.tellerid = JSON.stringify(e.tellerid);
      }
    });
  }

  postVoucher() {
    this._api.postVoucher(this.tf).subscribe((data: any) => {
      this.res = data; this.formReset();
      this._use.showMsg('success', 'Voucher Successfull', 'Payment has been posted successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Payment Fail', 'Unable to Post Payment Details');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.tf = {
        transcode: '', type: '', source: '', method: 'CASH', amount: 0, nominalid: '',
        reference: '', tellerid: '', userid: this._use.active.Id, date: this._use.date
      };
    }
  }

}
