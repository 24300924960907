import { Component, OnInit } from '@angular/core';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ManagerService } from 'src/app/services/manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-finance-account',
  templateUrl: './finance-account.component.html',
  styleUrls: ['./finance-account.component.css']
})
export class FinanceAccountComponent implements OnInit {
  accounts: any[] = []; filteredAccount: any[]; response: any; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filteredAccount = this.serachResult(value);
  }
  constructor(private api: ApiService, private _use: NavComponent, private _api: ManagerService) { }

  ngOnInit() {
    this.getAllAccounts();
  }

  getAllAccounts() {
    this._api.getAccounts().subscribe((data: any) => {
      this.accounts = data.data; this.filteredAccount = data.data;
      this.response = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  getMore(page,limit) {
    this.api.get('accounts?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.accounts = data.data; this.filteredAccount = data.data; this.response = data; // console.log(data);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  serachResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.accounts.filter(a => a.number.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.customer.fullname.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
