import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup-cot',
  templateUrl: './setup-cot.component.html',
  styleUrls: ['./setup-cot.component.css']
})
export class SetupCotComponent implements OnInit {
  @ViewChild('cotForm') public any: NgForm;
  cots: any[] = []; nom: any[] = []; cot: any; edit: Boolean; res: any; response: any; search: string = '';
  constructor(private api: ApiService, private _api: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllCot();
  }

  getAllCot() {
    this._api.getCots().subscribe((data: any) => {
      this.cots = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Cot List');
    });
    this.api.get('nominal/all').subscribe((data: any) => {
      this.nom = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Nominal List required for Cot setup');
    });
  }

  getMore(page,limit) {
    this.api.get('cot?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.cots = data.data; this.response = data; // console.log(data);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  editCot(data) {
    this.cot = data;
    this.edit = true;
  }

  delCot(dat) {
    this._api.delCot(dat.cotid).subscribe((data: any) => {
      this.res = data; this.cots.slice(dat);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Deleting Fail', 'Unable to Delete Cot');
    });
  }

  postCot() {
    this._api.postCot(this.cot).subscribe((data: any) => {
      this.res = data; this.getAllCot();
      this.formReset(this.any);
      this._use.showMsg('success', 'Cot Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Cot');
    });
  }

  putCot() {
    this.cot.muserid = this._use.active.id; this.cot.mdate = new Date();
    this._api.putCot(this.cot.cotid, this.cot).subscribe((data: any) => {
      this.res = data; this.getAllCot();
      this.formReset(this.any); this.edit = false;
      this._use.showMsg('success', 'Cot Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }
  
  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.cot = {
        cotid: 0, type: '', name: '', nominalid: null, amount: '', frequency: '', merchantid: this._use.active.merchantid,
        userid: this._use.active.id, date: new Date(), muserid: null, mdate: null
      };
    }
  }

}
