import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TellerService } from 'src/app/services/teller.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  employee: any[]; res: any; response: any; search: string = '';
  constructor(private api: ApiService, private _emp: TellerService, private _use: NavComponent) { }

  ngOnInit() {
    this.getAllEmployee();
  }

  getAllEmployee() {
    this._emp.getEmployees().subscribe((data: any) => {
      this.employee = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Employee List Fail', 'Unable to Fetch Employee List');  });
  }
  
  getMore(page,limit) {
    this.api.get('employee?search='+this.search+'&page='+page+'&limit='+limit).subscribe((data: any) => {
      this.employee = data.data; this.response = data; console.log(data);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  delEmp(dat) {
    this._emp.delEmployee(dat.employeeid).subscribe((data: any) => {
      this.res = data; this.employee.slice(dat);
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Delete Fail', 'Unable to Delete Selected Employee');  });
  }

}
