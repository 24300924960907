import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { TellerService } from 'src/app/services/teller.service';
import { SetupService } from 'src/app/services/setup.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-finance-transfer',
  templateUrl: './finance-transfer.component.html',
  styleUrls: ['./finance-transfer.component.css']
})
export class FinanceTransferComponent implements OnInit {
  cash: any; tf: any; teller: any[]; cashbook: any[]; res: any;
  constructor(private api: ApiService, private _api: TellerService, private _set: SetupService, private _use: NavComponent) { }

  ngOnInit() {
    this.formReset();
    this.getAllTellers();
  }

  getAllTellers() {
    this._api.getTellers().subscribe((data: any) => {
      this.teller = data; console.log(this.teller);
    }, (err: HttpErrorResponse) => { });
    this._set.getNominalsByGlType('Cashbook').subscribe((data: any) => {
      this.cashbook = data; console.log(this.cashbook);
    }, (err: HttpErrorResponse) => { });
  }

  Cal() {
    this.cash.frombal = this.cash.from - this.tf.amount;
    this.cash.tobal = this.cash.to + this.tf.amount;
  }

  from() {
    this._api.getNominalBalance(this.tf.nominalid).subscribe((data: any) => {
      this.cash.from = data; this.Cal();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Cashbook Balance', 'Unable to Your current Balance');
    });
  }
  to() {
    this._api.getTellerBalance(this.tf.tellerid).subscribe((data: any) => {
      this.cash.to = data; this.Cal();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Teller Balance', 'Unable to Teller current Balance');
    });
  }

  postTransfer() {
    this._api.postCashTransfer(this.tf).subscribe((data: any) => {
      this.res = data; this.formReset();
      this._use.showMsg('success', 'Transfer Successfull', 'Transfer Transaction has been posted successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Transfer Fail', 'Unable to Process Transfer');
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.tf = {
        id: 0,
        transcode: '', type: 'Debit', source: 'Financial Disburment', method: 'CASH', amount: 0, nominalid: '',
        reference: '', tellerid: '', userid: this._use.active.Id, date: this._use.date
      };
      this.cash = { frombal: 0, from: 0, tobal: 0, to: 0 };
    }
  }

}
